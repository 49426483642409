import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Card, Button, Navbar } from "react-bootstrap";

export default function App() {
  return (
    <>
      <Navbar expand="lg" className="custom-navbar">
        <Navbar.Brand className="text-white ms-4">Faktual.ai by Tanay Enzo Narang</Navbar.Brand>
      </Navbar>
      <div className="m-4">
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h1 className="mb-3">Welcome to Faktual.ai</h1>
            <p className="mb-4">
              An initiative to combat misinformation
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4} className="mb-4">
            <Card className="shadow-lg custom-card">
              <Card.Body>
                <Card.Title>AI Text Detector </Card.Title>
                <Card.Text>
                  State of the art text classification system that uses AI to classify if the text is AI generated or human generated. High accuracy system - absolutely free to use.
                </Card.Text>
                <Button
                  className="px-4"
                  variant="primary"
                  href="http://aidetect.faktual.ai/"
                  target="_blank"
                >
                  Visit
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="mb-4">
            <Card className="shadow-lg custom-card">
              <Card.Body>
                <Card.Title>AI Based Political Tweets Dashboard</Card.Title>
                <Card.Text>
                  Provides insights into tweets of political leaders, tracks follower count, categorizes positive and negative tweets of top 100 political leaders of India - political party wise.
                </Card.Text>
                <Button
                  className="px-4"
                  variant="primary"
                  href="http://tweets.faktual.ai/"
                  target="_blank"
                >
                  visit
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
